<template>
  <div class="fiat font-fiatnew">
    <div class="supheader bg-stellantis-500">
      <div class="container flex mx-auto flex-wrap w-10/12 p-1 items-center">
        <div class="w-full md:w-1/2 mb-2 md:mb-0">
          <img class="mx-auto md:mx-0" src="img/logo/STELLANTIS&YOU_logo_white_350x60.png" alt="" style="max-height: 50px">
        </div>
        <div class="w-full md:w-1/2 text-white">
          <p class="text-sm text-center md:text-right uppercase"><strong class="font-extrabold">FIAT STELLANTIS &YOU<br>Englos - Villeneuve d’Ascq</strong></p>
        </div>
      </div>
    </div>
   <!--  <div class="w-full py-2">
      <img src="img/logo/logo-fiat.png" alt="" class="w-20 mx-auto">
    </div> -->
    <div class="header w-full relative bg-cover bg-center bg-no-repeat py-5" style="background-image: url('img/pattern/bg_fiat500_2.jpg')">
      <div class="container flex mx-auto flex-wrap w-10/12 _fond items-center pb-8">
        <div class="w-full md:w-3/5 py-2 pl-2 pr-20">
          <!-- <img src="img/logo/logo_fiat_new.png" alt="" class="w-20 mb-4 pt-4 mx-auto md:mx-0"> -->
          <img src="img/divers/fiat500_2_heading.png" alt="">
          <img :src="img" alt="">
        </div>
        <div class="w-full md:w-2/5 text-white text-center md:text-left p-2">
          <div class="font-extrabold text-stellantisv2-blue">
            <!--<Tampon>Disponibilité<br>immédiate</Tampon>-->
            <font class="text-white uppercase text-3xl">Nouvelle 500 Électrique</font>
            <div class="text-2xl uppercase mt-2 font-normal">
              <span>à partir de</span><br>
              <font class="text-6xl">159&nbsp;€</font> <font class="text-4xl">/mois</font><sup class="inline-flex transform -translate-y-1.5 font-medium" style="font-size: 0.8rem;">(1)</sup>
            </div>
          </div>
          <Hr class="hidden md:inline-block hr-blue"/>
          <p class="mb-4 text-stellantisv2-blue">LLD 36 mois <font class="font-extrabold">sans condition de reprise</font>. <br>Après apport de 3 500 €, déduction faite du bonus écologique, <strong>ramené à 1&nbsp;000€ si éligibilité à la prime à la conversion.<sup class="inline-flex transform -translate-y-0 font-medium" style="font-size: 0.7rem;">(2)</sup></strong></p>
          <div class="flex flex-col">
            <Cta cls="btn3" marque="stellantisv2" class="mb-2"><font class="font-extrabold text-2xl uppercase">J'en profite</font></Cta>
            <Cta cls="btn3 outline" marque="stellantisv2" class="mb-2"><font class="font-extrabold uppercase">En savoir plus</font></Cta>
          </div>
        </div>
      </div>
    </div>
    <div class="h-12"></div>
    <section class="container flex flex-col text-center mx-auto flex-wrap w-10/12 mb-8">
      <div class="w-full">
        <div class="flex flex-wrap justify-center items-center mb-8">
          <img src="img/logo/badge500e.png" class="inline-block h-16" alt="">
        </div>
        <p class="uppercase">
          <font class="font-light text-fiat-500 text-3xl">80% des Fiat&nbsp;500 vendues sont en 100%&nbsp;électrique.</font><br>
          <font class="font-extrabold text-fiat-500 text-3xl md:text-5xl">Alors pourquoi pas vous&nbsp;?</font>
        </p>
      </div>
    </section>
    <section class="container flex flex-col text-center mx-auto flex-wrap w-10/12 mb-8">
      <div class="w-full">
        <div class="flex flex-wrap w-full items-center justify-center">
          <div class="w-full md:w-5/12 text-left p-2">
            <p class="mt-4">
              La Fiat&nbsp;500 100%&nbsp;électrique séduit par son design toujours aussi jovial et&nbsp;lumineux&nbsp;! Sa motorisation 100%&nbsp;électrique la rend encore plus maline et&nbsp;tellement responsable&nbsp;!<br>C’est l’objet d’un plaisir idéal pour tous vos déplacements quotidiens grâce à une autonomie électrique allant jusqu’à 320&nbsp;km* en WLTP avec la&nbsp;batterie haute capacité.
            </p>
          </div>
          <div class="w-full md:w-5/12 p-2">
            <img src="img/divers/500bev-ElectricFeatures-one-pedal-driving-Desktop-480x435.gif" alt="">
          </div>
        </div>
      </div>
    </section>
    <section class="w-full bg-fiat-200">
      <div class="container flex flex-col text-center mx-auto flex-wrap w-8/12 md:w-1/2 my-12 py-12">
        <font class="w-full text-left text-3xl font-bold pb-4 block text-center">En conduite urbaine, l’autonomie atteint de 257 à&nbsp;460&nbsp;km* selon le modèle&nbsp;choisi.</font>
        <p>De quoi faire sans mal plusieurs fois le tour de Lille avant de retourner brancher votre Fiat 500 Électrique au bureau, à votre domicile ou sur une borne publique !</p>
        <Cta cls="btn1" marque="fiat" class="my-5">JE REJOINS LE CLUB 500e</Cta>
      </div>
    </section>
    <section class="w-full">
      <div class="container flex text-center mx-auto flex-wrap w-8/12 my-12 py-12">
        <font class="w-full text-left text-3xl font-bold pb-4 block text-center">Selon vos envies de confort et de technologies</font>
        <p class="w-full mb-6">Équipements de série ou en option selon la finition et vos envies.</p>
        <div class="w-full md:w-1/2 lg:w-1/3 p-4 text-left">
          <font class="font-extrabold block">Confort & Technologie à bord</font>
          <ul class="list-disc pl-5">
            <li>Capteur de pluie</li>
            <li>Climatisation automatique</li>
            <li>Connectivité Apple CarPlay<sup class="text-sm">®</sup> / Android&nbsp;Auto™</li>
            <li>Radio Digital Audio Broadcast (DAB)</li>
            <li>Frein de stationnement électrique</li>
            <li>Limiteur de vitesse intelligent</li>
            <li>Ouverture et démarrage sans clé</li>
            <li>Radar de recul</li>
            <li>Régulateur de vitesse</li>
            <li>Système Uconnect™ avec écran 10,25" et navigation</li>
            <li>Volant gainé en éco-cuir</li>
          </ul>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 p-4 text-left">
          <font class="font-extrabold block">Sécurité</font>
          <ul class="list-disc pl-5">
            <li>Alerte de franchissement de ligne</li>
            <li>Appel d’urgence e-Call</li>
            <li>Capteur de luminosité</li>
            <li>Détecteur de fatigue</li>
            <li>Freinage automatique d’urgence</li>
            <li>Reconnaissance des panneaux de signalisation</li>
          </ul>
        </div>
        <div class="w-full md:w-1/2 lg:w-1/3 p-4 text-left">
          <font class="font-extrabold block">Univers électrique</font>
          <ul class="list-disc pl-5">
            <li>Batterie jusqu’à 42&nbsp;kW</li>
            <li>Câble mode 2 (recharge à domicile</li>
            <li>Recharge rapide jusqu’à 50 ou 85&nbsp;k</li>
            <li>Services Uconnect™</li>
          </ul>
          <img src="img/divers/App-FIAT-desktop-570x322.jpg" class="mt-5" alt="">
        </div>
      </div>
    </section>
    
    <Carousel :content="exterieur" titre="Tour d’horizon extérieur" />
    <div class="h-12"></div>
    <Carousel :content="interieur" titre="Prenez place à bord" />
    <div class="w-10/12 md:w-1/2 mx-auto text-center mt-20 mb-32">
      <Cta cls="btn1" marque="fiat">JE VEUX EN PROFITER !</Cta><br><br>
      <a href="./stock/Brochure_catalogue_500_electrique.pdf" target="_blank" class="text-fiat-500 hover:underline">Je télécharge la brochure Fiat 500e</a>
    </div>
    <section class="w-full bg-fiat-200">
      <div class="container flex flex-col text-center mx-auto flex-wrap w-8/12 md:w-1/2 my-12 py-12">
      <img src="img/logo/STELLANTIS-and-you-logo-blue.png" alt="" class="w-64 mx-auto mb-12">
        <p>
          <strong>Fiat Stellantis &You Lille</strong> (anciennement appelé Fiat Motor Village Lille) est une garantie de professionnalisme, de qualité et de passion pour le monde de l’automobile.<br /><br />
          Chez <strong>Fiat Stellantis &You Lille</strong>, nous vous présentons un large éventail de véhicules neufs. Vous y trouverez un personnel attentionné et compétent à l’écoute de vos besoins. Il vous guidera dans vos choix, pour vous recommander un financement sur mesure et configurer le véhicule de vos souhaits.<br /><br />
          <strong>Stellantis &You Lille</strong> propose également des offres de financement aux particuliers et aux professionnels sur les marques <strong>Alfa&nbsp;Romeo, Abarth et Fiat&nbsp;Professional</strong>. 
        </p>
      </div>
    </section>
    <div class="w-10/12 md:w-1/2 mx-auto text-center mt-20 mb-32">
      <Lom conso="0"/>
    </div>
    <Footer marque="stellantis">
      <p class="text-xs">
        (1) Exemple pour Nouvelle (500)RED MY23 berline 23,8kWh au tarif conseillé du 04/10/2022 en <strong>location longue durée sur 36&nbsp;mois et 30&nbsp;000 km maximum, soit 36&nbsp;loyers mensuels de 159&nbsp;€&nbsp;TTC après apport de 9&nbsp;500&nbsp;€&nbsp;TTC ramené à 3&nbsp;500&nbsp;€ après déduction du bonus écologique de 6&nbsp;000 €</strong>. Offre sans condition de reprise, non cumulable avec d'autres offres en cours, réservée aux particuliers et <strong>valable jusqu’au&nbsp;31/10/2022</strong> dans le réseau Fiat participant. Sous réserve d'acceptation de votre dossier par LEASYS France SAS - 1 Rue Victor Basch - 91300 Massy - 413 360 181 RCS Evry. Courtier en assurance enregistré à l'ORIAS n.08045147.<br />
        (2) Prime à la conversion de 2 500 € sous condition de mise au rebut d’une voiture ou d’une camionnette (PTAC > 3,5 t) immatriculée avant 2011 (Diesel) ou 2006 (essence) selon décret en vigueur. Conditions d’éligibilité sur <a href="https://www.primealaconversion.gouv.fr" target="_blank">www.primealaconversion.gouv.fr</a>. <strong>Modèle présenté :</strong> Nouvelle 500 La Prima berline MY23 23,8kWh <strong>(237&nbsp;€&nbsp;TTC/mois aux mêmes conditions)</strong>.<br />
        <strong>(3) Sur la base des ventes dans le réseau FIAT du 01/01/2022 au 30/09/2022.</strong><br /><br />
        <strong>Gamme Nouvelle Fiat&nbsp;500 : Consommations min/max (Wh/km) : de 130 à 149 ; émissions de CO2 (g/km) : 0 à l’usage. Jusqu’à 320km d'autonomie électrique en WLTP et jusqu'à 460km d'autonomie électrique en ville en WLTP.</strong>
      </p>
    </Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import Carousel from '@/components/Carousel.vue'
import Hr from '@/components/Hr.vue'
import Tampon from '@/components/Tampon.vue'
import Advantage from '@/components/Advantage.vue'
import Lom from '@/components/Lom.vue'
export default {
  props: {
    contrat: String,
    img: String,
    offre: String,
    title: String,
  },
  components: {
    Hr,
    Tampon,
    Footer,
    Carousel,
    Advantage,
    Lom
  },
  data(){
    return{
      brand: 'leasys',
      avantages: [
        {img: 'img/icons/thumb1.png', text: "100% digital<br>De la réservation au paiement"},
        {img: 'img/icons/thumb2.png', text: "Recharges gratuites dans tous les Leasys Mobility Stores"},
        {img: 'img/icons/thumb3.png', text: "Sans engagement<br>Vous arrêtez quand vous voulez"},
        {img: 'img/icons/thumb4.png', text: "1 500 km/mois inclus"},
        {img: 'img/icons/thumb5.png', text: "Assurance tous risques incluse"},
        {img: 'img/icons/thumb6.png', text: "Entretien et assistance 24/7"},
      ],
      exterieur: [
        {
          titre: 'Séduisante…',
          img: 'img/cars/500e-8.jpg'
        },
        {
          titre: '… à chaque point de vue…',
          img: 'img/cars/500e-7.jpg'
        },
        {
          titre: '… et sous tous les angles.',
          img: 'img/cars/500e-4.jpg'
        },
        {
          titre: 'Trappe parfaitement intégrée pour un style préservé.',
          img: 'img/cars/500e-9.jpg'
        }
      ],
      interieur: [
        {
          titre: 'Rencontre au sommet entre design intemporel et technologie d’avant&#8209;garde.',
          img: 'img/cars/500e-5.jpg'
        },
        {
          titre: 'Tellement plus d’espace qu’il n’y paraît.',
          img: 'img/cars/500e-6.jpg'
        },
        {
          titre: 'Une petite citadine très branchée.',
          img: 'img/cars/500e-1.jpg'
        },
        {
          titre: 'Digital jusqu’au bout des ongles.',
          img: 'img/cars/500e-2.jpg'
        }
      ]
    }
  },
  mounted(){
    this.$store.commit('setForm', {id: '222852039168358', model: '500e', carImg: this.img, carName: this.title, confirmID: 1})
  }
}
</script>

<style lang="scss">
body { font-family: 'Poppins', sans-serif; }
.header{
  //background-color: var(--color7);
  ._fond{
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.text-shadow{
  text-shadow: 1px 1px 13px rgb(0 0 0 / 24%);
}
.hr-blue {
  background: #093664!important;
}
</style>